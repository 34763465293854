<script>
import {
  required,
  // email,
  //   minLength,
  // sameAs,
  //   maxLength,
  // minValue,
  // maxValue,
  // numeric,
  // url,
  // alphaNum,
} from "vuelidate/lib/validators";

import Swal from "sweetalert2";
import useNetw from "@useNetw";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
// import tableData from "@/components/tablecomponent";
// import { LGeoJson } from "vue2-leaflet";

/**
 * Form validation component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
DatePicker,
    // Switches,
    // NumberInputSpinner,*/
  },
  page: {
    title: "เพิ่มงานนอก",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  computed: {
    /**
     * Total no. of records
     */
  },

  created() {
    this.getLocalData();
  },

  data() {
    return {
      submitformOS: false,

      title: "เพิ่มใบรับสั่งงานนอก",
      items: [
        {
          text: "บริการ",
        },
        {
          text: "รับสั่งงานนอก",
        },
        {
          text: "เพิ่มใบรับสั่งงานนอก",
        },
      ],
      stepOS: false,
      outsourcingId: "",
      serviceSelect: [],
      amount: [],
      dcPer: 0,
      dcPrice: 0,
      netPrice: 0,
      vatPer: 7,
      vatPrice: 0,
      rowOS:[],
      osx:[],
      dateSearch:'',
      


      page:1,
      perPage:100,

      loading: undefined,
      optionsSub: [],
      localDataBranch: [],
      localData: [],
      svId: "",
      roId: "",
      rowSv: [],
      rowRo: [],
      optionsService: [],

      rowService: [],
      fieldsOS: [
        {
          key: "outsourcingDate",
          sortable: true,
          label: "วันที่",
        },
        {
          key: "outsourcingCode",
          sortable: true,
          label: "รหัสใบสั่งงานนอก",
        },
        {
          key: "licensePlate",
          sortable: true,
          label: "ทะเบียนรถ",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
        {
          key: "supplierNameTh",
          sortable: true,
          label: "บริษัท",
        },
        {
          key: "status",
          sortable: true,
          label: "สถานะ",
        },
        
        {
          key: "action",
          label: "แก้ไข",
        },
      ],

      error: "กรุณาตรวจสอบข้อมูลอีกครั้ง.",

      formOS: {
        branchId: "",
        outsourcingId: "",
      },
    };
  },
  validations: {
    formOS: {
      branchId: {},
      outsourcingId: {
        required,
      },
    },
  },

  methods: {
    onRowSelected(os){
      console.log(os[0].outsourcingId);
      this.formOS.outsourcingId = os[0]
      this.$refs.modalOS.hide();
    },
    popupmodalAddOS(data) {
      console.log("select Services=>", data);
      this.$refs.modalAddService.show();
    },
    customLabelService({ osCode, nameTh }) {
      return `[${osCode}] - ${nameTh}`;
    },
    postBranchOS: function() {
      // this.overlayFlag = true;
      const osPost = new FormData();

      osPost.append("outsourcingId", this.formOS.outsourcingId.outsourcingId);

      useNetw
        .post("api/received-outsourcing/store", osPost)
        .then((response) => {
          console.log(response.data.data);

          this.$router.push({
            name: "edit-received-outsourcing",
            params: {
              recOsId: response.data.recOsId,
            },
          });
          // this.getdataService();

          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
      });
      this.localDataBranch = localDataBranch;
      this.branchId = user.branchId;
    },

    formOSSubmit() {
      this.$v.$touch();
      if (this.$v.formOS.$invalid) {
        this.submitformOS = true;
      } else {
        this.postBranchOS();
      }
    },

    getOS: function() {
      // this.overlayFlag = true;
      useNetw
        .get("api/outsourcing", {
          params: {
            page: 1,
            perPage: 100,
            branchId: [this.formOS.branchId.branchId],
            outsourcingDate: this.dateSearch,
          },
        })
        .then((response) => {
          this.rowOS = response.data.data;
          this.getDataSv();
          this.getDataBranchService();
          // console.log(response);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    getDataBranchService: function() {
      // this.overlayFlag = true;
      useNetw
        .get("api/outsourcing/outsourcing-service", {
          params: {
            page: 1,
            perPage: 100,
            branchId: [this.formOS.branchId.branchId],
          },
        })
        .then((response) => {
          this.optionsService = response.data.data;
          //   console.log("Service=>", this.optionsService);
          // console.log(response);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    getDataSv() {
      useNetw
        .get("api/service", {
          params: {
            page: 1,
            perPage: 100,
            branchId: [this.formOS.branchId.branchId],
          },
        })
        .then((response) => {
          this.rowSv = response.data.data;
          console.log("Sv=>", this.rowSv);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          this.isLoading = false;
        });
    },
    getDataRo() {
      useNetw
        .get("api/service/ro", {
          params: {
            page: 1,
            perPage: 100,
            svId: this.svId.svId,
          },
        })
        .then((response) => {
          this.rowRo = response.data.data;
          console.log("Ro=>", this.rowRo);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <b-skeleton-wrapper :loading="loading"
          ><!--// !skeleton start-->
          <template #loading>
            <br />
            <b-skeleton animation="wave" width="10%"></b-skeleton>
            <b-skeleton animation="wave" width="15%"></b-skeleton>

            <br />
            <div class="row">
              <div class="col-md-2">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
              <div class="col-md-3">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
            </div>
            <br />
            <div class="row">
              <br />
              <div class="col-md-3">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
              <div class="col-md-3">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
              <div class="col-md-3">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
            </div>
            <br />

            <div class="card">
              <b-skeleton-table
                :rows="5"
                :columns="2"
                :table-props="{ bordered: false, striped: true }"
              ></b-skeleton-table>
            </div>
          </template>
          <!--// !skeleton ends-->

          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6 my-auto"></div>
                <div class="col-md-6 text-end">
                  <router-link :to="{ name: 'main-outsourcing' }">
                    <button class="btn btn-secondary">กลับสู่หน้าหลัก</button>
                  </router-link>
                </div>
              </div>
              <hr />
              <form class="needs-validation" @submit.prevent="formOSSubmit()">
                <div class="row">
                  <div class="col-md-6">
                    
                        <code>* </code
                        ><label for="branchId">เลขใบสั่งงานนอก</label>
                        <multiselect
                          v-model="formOS.outsourcingId"
                          :options="rowOS"
                          label="outsourcingCode"
                          :class="{
                            'is-invalid':
                              submitformOS && $v.formOS.outsourcingId.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitformOS && $v.formOS.outsourcingId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.formOS.outsourcingId.required">{{
                            error
                          }}</span>
                        </div>
                  </div>
                  <div class="col-md-auto">
                    <label for="btn">&nbsp;</label><br>
                    <a
                          class="btn btn-primary"
                          @click="$refs.modalOS.show()"
                        >
                          <i class="uil uil-search"></i> เลือกจากใบสั่งงานนอก
                        </a>
                  </div>
                  
                  <div class="col text-end">
                    <label for="">&nbsp;</label><br>
                  <button type="submit" class="btn btn-success">บันทึก</button>
                </div>

                </div>
                <!--//! End Rows -->

                
                <hr />
              </form>
            </div>
          </div>
          <!-- //!end card -->
        </b-skeleton-wrapper>

        <!-- //!end card -->
      </div>
    </div>
    <b-modal
      title="เลือกใบสั่งงานนอก"
      hide-footer
      size="lg"
      centered
      ref="modalOS"
      
    >
      <div class="row">
        <div class="col-md-6">
          <label for="branchId">สาขา</label>
          <multiselect
            v-model="formOS.branchId"
            :options="localData"
            label="nameTh"
            @input="getOS"
            :class="{
              'is-invalid': submitformOS && $v.formOS.branchId.$error,
            }"
          >
          </multiselect>
          <div
            v-if="submitformOS && $v.formOS.branchId.$error"
            class="invalid-tooltip"
          >
            <span v-if="!$v.formOS.branchId.required">{{ error }}</span>
          </div>
        </div>
         <div class="col-6 col-sm-6 col-lg-4">
          <div class="mb-3 position-relative">
            <code>* </code><label for="validation">วันที่</label>
            <date-picker
              v-model="dateSearch"
              format="YYYY-MM-DD"
              value-type="format"
            ></date-picker>
          </div>
        </div>
        <div class="col-md-2">
          <label for="btnRo">&nbsp;</label><br>
          <button class="btn btn-primary" @click="getOS"><i class="uil uil-search"></i>ค้นหา</button>
        </div>
      </div>
      <div class="row mt-3">

        <div class="table-responsive mb-0">
            <b-table
              :items="rowOS"
              :fields="fieldsOS"
              responsive="true"
              :per-page="perPage"
              :current-page="1"
              ref="selectableTable"
              selectable
              select-mode="single"
              @row-selected="onRowSelected"
            >
            </b-table>
          </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-lg-12">
          <div class="text-end">
            <button class="btn btn-success">
              เพิ่ม
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>
